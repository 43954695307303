import React, { useEffect, useState } from 'react';
import './style.css';
import { Grid } from '@mui/material';
import Register from '../Register/register';
import homepage from './../../assets/homepge.png';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/loader';
import login from '../../services/auth';

const Login = () => {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const [formValue, setFormValue] = useState({ email: '', password: '' });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async () => {
    try {
      if (!formValue.email || !formValue.password) {
        setError(true);
        setErrorMessage('Please fill in all fields.');
        return;
      }

      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(formValue.email)) {
        setError(true);
        setErrorMessage('Invalid email format.');
        return;
      }

      setLoading(true);
      await login(formValue);
      setLoading(false);
      navigate('/dashboard');
    } catch (error) {
      setLoading(false);
      setError(true);
      setErrorMessage(error?.message ? `${error?.message}.` : 'An error occurred. Please try again later.');
    }
  };

  useEffect(() => {
    const isAuthenticated = localStorage.getItem('er_a_token');
    if (isAuthenticated) {
      navigate('/dashboard');
    }
  }, [navigate]);

  return (
    <Grid
      container
      alignItems="center"
      style={{
        height: '100vh',
        backgroundImage: `url(${homepage})`,
        backgroundSize: 'contain',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <div className="custom-container">
        <h1 className='custom-h1'>Smart Energy Router</h1>
        <h2 className='custom-h1'>for residential buildings</h2>
        <p className='description'>
          Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
        </p>
      </div>

      <Grid
        container
        xs={12}
        md={4}
        className='container container-right'
        mt={10}
        mr={10}
        mb={10}
      >
        <Grid item xs={12}>
          <h2 className='login'>Login to your account</h2>
        </Grid>

        <Grid item xs={12} mt={3} mb={2}>
          <p className='descriptions'>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </Grid>
        <Grid item xs={12} mt={3} mb={2}>
          <form>
            <Grid item xs={12} mt={2}>
              <div>
                <label htmlFor='username' className='device'>
                  Device Serial Number
                </label>
                <input
                  required
                  className='custom-input'
                  type='text'
                  id='username'
                  placeholder='example@example.com'
                  value={formValue.email}
                  onChange={(e) => {
                    setFormValue((prev) => ({ ...prev, email: e.target.value }));
                  }}
                />
                {error && formValue.email?.length <= 0 ? (
                  <div className='error error-toaster'>Serial Number Required</div>
                ) : (
                  ''
                )}
              </div>
            </Grid>
            <Grid item xs={12} mt={2}>
              <label htmlFor='password' className='password'>
                Password
              </label>
              <input
                required
                type='password'
                id='password'
                placeholder='************'
                value={formValue.password}
                onChange={(e) => {
                  setFormValue((prev) => ({ ...prev, password: e.target.value }));
                }}
              />
              {error && formValue.password?.length <= 0 ? (
                <div className='error-toaster'>Password Required</div>
              ) : (
                ''
              )}
            </Grid>

            <Grid item xs={12}>
              <button
                type='button'
                onClick={handleLogin}
                className={loading ? 'disable-button-login' : 'button-login'}
                disabled={loading}
              >
                {loading ? (<Loader />) : 'Login'}
              </button>
            </Grid>

            {error && (
              <Grid item xs={12} mt={2}>
                <div className='error'>{errorMessage}</div>
              </Grid>
            )}

            <Grid item xs={12}>
              <span className='d-acc'>Don’t have an account?</span>
              <button
                className='register'
                type='button'
                onClick={() => setOpen(true)}
              >
                &nbsp; Register now
              </button>
            </Grid>
          </form>
        </Grid>
      </Grid>
      <Register open={open} setOpen={setOpen} />
    </Grid>
  );
};

export default Login;
