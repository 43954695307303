import axiosClient from "./axiosInstance";

export async function getPowerConsumptions(pageSize?: number, pageNumber?: number) {
    const result = axiosClient().get(`energy-monitor/power-consumption?pageSize=${pageSize ?? 10}&pageNumber=${pageNumber ?? 1}`).then((response) => {
        return response;
    });
    return result;
}

export async function getSolarGenerations(pageSize?: number, pageNumber?: number) {
    const result = axiosClient().get(`energy-monitor/solar-generation?pageSize=${pageSize ?? 10}&pageNumber=${pageNumber ?? 1}`).then((response) => {
        return response;
    });
    return result;
}

export async function getBatteryConsumptions(pageSize?: number, pageNumber?: number) {
    const result = axiosClient().get(`energy-monitor/power-battery?pageSize=${pageSize ?? 10}&pageNumber=${pageNumber ?? 1}`).then((response) => {
        return response;
    });
    return result;
}

export async function getAcConsumptions(pageSize?: number, pageNumber?: number) {
    const result = axiosClient().get(`energy-monitor/ac-load?pageSize=${pageSize ?? 10}&pageNumber=${pageNumber ?? 1}`).then((response) => {
        return response;
    });
    return result;
}

export async function getDcConsumptions(pageSize?: number, pageNumber?: number) {
    const result = axiosClient().get(`energy-monitor/dc-load?pageSize=${pageSize ?? 10}&pageNumber=${pageNumber ?? 1}`).then((response) => {
        return response;
    });
    return result;
}


export async function getSolarFullDay() {
    const result = axiosClient().get(`energy-monitor/solar-generation-day`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getSolarWeek() {
    const result = axiosClient().get(`energy-monitor/solar-generation-week`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}


export async function getSolarMonth() {
    const result = axiosClient().get(`energy-monitor/solar-generation-month`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}


export async function getPowerConsumptionFullDay() {
    const result = axiosClient().get(`energy-monitor/power-consumption-day`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getPowerConsumptionWeek() {
    const result = axiosClient().get(`energy-monitor/power-consumption-week`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}


export async function getPowerConsumptionMonth() {
    const result = axiosClient().get(`energy-monitor/power-consumption-month`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getBatteryConsumptionDay() {
    const result = axiosClient().get(`energy-monitor/power-battery-day`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getBatteryConsumptionWeek() {
    const result = axiosClient().get(`energy-monitor/power-battery-week`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getBatteryConsumptionMonth() {
    const result = axiosClient().get(`energy-monitor/power-battery-month`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getAcConsumptionDay() {
    const result = axiosClient().get(`energy-monitor/ac-load-day`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getAcConsumptionWeek() {
    const result = axiosClient().get(`energy-monitor/ac-load-week`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getAcConsumptionMonth() {
    const result = axiosClient().get(`energy-monitor/ac-load-month`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getDcConsumptionDay() {
    const result = axiosClient().get(`energy-monitor/dc-load-day`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getDcConsumptionWeek() {
    const result = axiosClient().get(`energy-monitor/dc-load-week`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getDcConsumptionMonth() {
    const result = axiosClient().get(`energy-monitor/dc-load-month`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getPowerChart() {
    const result = axiosClient().get(`energy-monitor/power-consumption-chart`).then((response) => {
        return response;
    });
    return result;
}

export async function getGridChart() {
    const result = axiosClient().get(`energy-monitor/grid-consumption-chart`).then((response) => {
        return response;
    });
    return result;
}

export async function getBatteryChart() {
    const result = axiosClient().get(`energy-monitor/power-battery-chart`).then((response) => {
        return response;
    });
    return result;
}

export async function getAcChart() {
    const result = axiosClient().get(`energy-monitor/ac-load-chart`).then((response) => {
        return response;
    });
    return result;
}

export async function getDcChart() {
    const result = axiosClient().get(`energy-monitor/dc-load-chart`).then((response) => {
        return response;
    });
    return result;
}

export async function getPowerChartWeek() {
    const result = axiosClient().get(`energy-monitor/power-consumption-chart-week`).then((response) => {
        return response;
    });
    return result;
}

export async function getGridChartWeek() {
    const result = axiosClient().get(`energy-monitor/grid-consumption-chart-week`).then((response) => {
        return response;
    });
    return result;
}

export async function getBatteryChartWeek() {
    const result = axiosClient().get(`energy-monitor/power-battery-chart-week`).then((response) => {
        return response;
    });
    return result;
}

export async function getAcChartWeek() {
    const result = axiosClient().get(`energy-monitor/ac-load-chart-week`).then((response) => {
        return response;
    });
    return result;
}

export async function getDcChartWeek() {
    const result = axiosClient().get(`energy-monitor/dc-load-chart-week`).then((response) => {
        return response;
    });
    return result;
}

export async function getPowerChartMonth() {
    const result = axiosClient().get(`energy-monitor/power-consumption-chart-month`).then((response) => {
        return response;
    });
    return result;
}

export async function getGridChartMonth() {
    const result = axiosClient().get(`energy-monitor/grid-consumption-chart-month`).then((response) => {
        return response;
    });
    return result;
}

export async function getBatteryChartMonth() {
    const result = axiosClient().get(`energy-monitor/power-battery-chart-month`).then((response) => {
        return response;
    });
    return result;
}

export async function getAcChartMonth() {
    const result = axiosClient().get(`energy-monitor/ac-load-chart-month`).then((response) => {
        return response;
    });
    return result;
}

export async function getDcChartMonth() {
    const result = axiosClient().get(`energy-monitor/dc-load-chart-month`).then((response) => {
        return response;
    });
    return result;
}

export async function getDashboardData() {
    const result = axiosClient().get(`energy-monitor/power-dashboard`).then((response) => {
        return response;
    });
    return result;
}

export async function getDashboardDataNew() {
    const result = axiosClient().get(`energy-monitor/power-dashboard-new`).then((response) => {
        return response;
    });
    return result;
}

export async function getPredictionDataChart() {
    const result = axiosClient().get(`energy-monitor/prediction-data`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}

export async function getPredictionDataChartNew() {
    const result = axiosClient().get(`energy-monitor/prediction-data-new`).then((response) => {
        return response;
    });
    console.log(result);
    return result;
}


export async function getEnergyPrice() {
    const result = axiosClient().get(`energy-price`).then((response) => {
        return response;
    });
    return result;
}
