import React, { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { Grid, Modal } from '@mui/material';
import TextField from '@mui/material/TextField';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import axiosClient from './../../services/axiosInstance';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';



const steps = ['Set Device', 'User Info', 'Password Setup'];

const SetupDevice = ({ deviceId, email, isDeviceIdValid, isEmailValid, setDeviceId, setEmail, setDeviceIdError, setEmailError }) => {
    const handleDeviceIdChange = (event) => {
        setDeviceId(event.target.value);
        setDeviceIdError(false);
    };

    const handleEmailChange = (event) => {
        setEmail(event.target.value);
        setEmailError(false);
    };

    const checkDeviceIdExistence = async () => {
        try {
            const response = await axiosClient().post('auth/user-exists', JSON.stringify({ deviceId }));
            if (response.status > 199 && response.status < 300) {
                setDeviceIdError(!!response.data?.deviceId);
            }
        } catch (error) {
            console.error('Error checking device ID existence:', error);
        }
    };

    const checkEmailExistence = async () => {
        try {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!emailRegex.test(email)) {
                setEmailError(true);
                return;
            }
            const response = await axiosClient().post('auth/user-exists', JSON.stringify({ email }));
            if (response.status > 199 && response.status < 300) {
                setEmailError(!!response.data?.email);
            }
        } catch (error) {
            console.error('Error checking email existence:', error);
        }
    };

    return (
        <Grid container xs={12} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} p={2}>
                <TextField
                    error={!isDeviceIdValid}
                    id="deviceId"
                    fullWidth
                    onChange={handleDeviceIdChange}
                    onBlur={checkDeviceIdExistence}
                    type='text'
                    label="Device Serial Number"
                    value={deviceId}
                    placeholder='xxxx-xxxxx-xxxx'
                    helperText={!isDeviceIdValid && 'Please enter your device serial number.'}
                />
            </Grid>
            <Grid item xs={12} paddingX={2}>
                <TextField
                    error={!isEmailValid}
                    id="email"
                    fullWidth
                    onChange={handleEmailChange}
                    onBlur={checkEmailExistence}
                    type='email'
                    label="Email"
                    value={email}
                    placeholder='example@example.com'
                    helperText={!isEmailValid && 'Please enter a valid email address.'}
                />
            </Grid>
        </Grid>
    );
};

const StepTwo = ({ userInfo, setUserInfo, setIsUserInfoValid }) => {
    const handleInputChange = (event) => {
        setUserInfo({ ...userInfo, [event.target.id]: event.target.value });
    };

    const validateStep = () => {
        setIsUserInfoValid(!!userInfo.firstName && !!userInfo.lastName && !!userInfo.mobile && !!userInfo.gender);
    };

    return (
        <Grid container xs={12} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} paddingTop={2} paddingX={0.4} paddingBottom={0.4}>
                <TextField
                    id='firstName'
                    label='First Name'
                    type='text'
                    fullWidth
                    placeholder='John'
                    value={userInfo.firstName}
                    onChange={handleInputChange}
                    onBlur={validateStep}
                />
            </Grid>
            <Grid item xs={12} p={0.4}>
                <TextField
                    id='lastName'
                    label='Last Name'
                    type='text'
                    placeholder='Doe'
                    fullWidth
                    value={userInfo.lastName}
                    onChange={handleInputChange}
                    onBlur={validateStep}
                />
            </Grid>
            <Grid item xs={12} p={0.4}>
                <TextField
                    id='mobile'
                    label='Mobile'
                    type='tel'
                    fullWidth
                    placeholder='+372-000-0000'
                    value={userInfo.mobile}
                    onChange={handleInputChange}
                    onBlur={validateStep}
                />
            </Grid>
            <Grid item xs={12} paddingX={0.4} paddingTop={0.4}>
                <TextField
                    id='gender'
                    select
                    label='Gender'
                    value={userInfo.gender}
                    fullWidth
                    onChange={handleInputChange}
                    onBlur={validateStep}
                    SelectProps={{ native: true }}
                >
                    <option value='MALE' key='MALE'>Male</option>
                    <option value='FEMALE' key='FEMALE'>Female</option>
                    <option value='OTHER' key="OTHER">Other</option>
                </TextField>
            </Grid>
        </Grid>
    );
};

const StepThree = ({ password, confirmPassword, isPasswordValid, setPassword, setConfirmPassword, setIsPasswordValid, apiError }) => {
    const handleInputChange = (event) => {
        const { id, value } = event.target;
        if (id === 'password') {
            setPassword(value);
        } else if (id === 'confirmPassword') {
            setConfirmPassword(value);
        }
    };

    const validateStep = () => {
        setIsPasswordValid(!!password && !!confirmPassword && password === confirmPassword);
    };

    return (
        <Grid container xs={12} justifyContent={'center'} alignItems={'center'}>
            <Grid item xs={12} p={2}>
                <TextField
                    id='password'
                    label='Password'
                    type='password'
                    fullWidth
                    value={password}
                    onChange={handleInputChange}
                    onBlur={validateStep}
                    inputProps={{ maxLength: 20, minLength: 8 }}
                />
            </Grid>
            <Grid item xs={12} paddingX={2} >
                <TextField
                    id='confirmPassword'
                    label='Confirm Password'
                    type='password'
                    fullWidth
                    value={confirmPassword}
                    onChange={handleInputChange}
                    onBlur={validateStep}
                    inputProps={{ maxLength: 20, minLength: 8 }}

                />
            </Grid>
            <Grid item xs={12} p={2}>
                {console.log('Asasdadasdasdsdascsad', apiError)}
                {apiError.status && <Typography variant='caption' color='error'>{apiError.message}</Typography>}
            </Grid>
        </Grid>
    );
};

export default function Register(props) {
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = useState(0);
    const [completedSteps, setCompletedSteps] = useState(new Set());
    const [isDeviceIdValid] = useState(true);
    const [isEmailValid] = useState(true);
    const [userInfo, setUserInfo] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        gender: ''
    });
    const [setIsUserInfoValid] = useState(false);
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [deviceId, setDeviceId] = useState('');
    const [isPasswordValid, setIsPasswordValid] = useState(false);
    const [setDeviceIdError] = useState(false);
    const [setEmailError] = useState(false);
    const [apiError, setApiError] = useState({ status: false, message: '' });

    const isStepOptional = (step) => {
        return false;
    };

    const isStepSkipped = (step) => {
        return !completedSteps.has(step);
    };
    const validateStep = useCallback((step) => {
        if (step === 0) {
            return !!deviceId && !!email;
        } else if (step === 1) {
            return !!userInfo.firstName && !!userInfo.lastName && !!userInfo.mobile && !!userInfo.gender;
        } else if (step === 2) {
            return !!password && !!confirmPassword && password === confirmPassword;
        }
        return false;
    }, [confirmPassword, deviceId, email, password, userInfo.firstName, userInfo.gender, userInfo.lastName, userInfo.mobile]);

    useEffect(() => {
        const isValid = validateStep(activeStep);
        if (isValid) {
            const newCompletedSteps = new Set(completedSteps);
            newCompletedSteps.add(activeStep);
            setCompletedSteps(newCompletedSteps);
        }
    }, [activeStep, deviceId, email, userInfo, password, confirmPassword, validateStep, completedSteps]);

    const handleNext = () => {
        const isValid = validateStep(activeStep);
        if (isValid) {
            const newCompletedSteps = new Set(completedSteps);
            newCompletedSteps.add(activeStep);
            setCompletedSteps(newCompletedSteps);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => {
            return activeStep === 0 ? activeStep : prevActiveStep - 1;
        });
    };

    const handleRegister = async () => {
        const user = {
            deviceId,
            email,
            ...userInfo,
            password,
            roleId: 2,
        };

        try {
            const response = await axiosClient().post('auth/register', JSON.stringify(user));
            if (response.status > 199 && response.status < 300) {
                const data = await response.data;
                localStorage.setItem('er_a_token', data.accessToken);
                localStorage.setItem('user', JSON.stringify(data.user));
                navigate('/dashboard');
                return;
            } else {
                setApiError({ status: true, message: response.data?.message });
            }
        } catch (error) {
            setApiError({ status: true, message: error?.response?.data?.message ? `${error?.response?.data?.message}.` : 'An error occurred. Please try again later.' });

            console.log('Error: ', error);
        }
        console.log('Registering user', user);
    };

    return (
        <Grid container xs={12} justifyContent={'center'} alignContent={'center'} alignItems={'center'} >
            <Modal open={props.open} onClose={() => props.setOpen(false)}>
                <Box sx={{
                    width: '30%',
                    height: '50%',
                    padding: "10px",
                    backgroundColor: "white",
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    borderRadius: '3%',
                }}>
                    <Stepper activeStep={activeStep}>
                        {steps.map((label, index) => {
                            const stepProps = {};
                            const labelProps = {};
                            if (isStepOptional(index)) {
                                labelProps.optional = (
                                    <Typography variant="caption"></Typography>
                                );
                            }
                            if (isStepSkipped(index)) {
                                stepProps.completed = false;
                            }
                            return (
                                <Step key={label} {...stepProps}>
                                    <StepLabel {...labelProps} onClick={handleBack}></StepLabel>
                                </Step>
                            );
                        })}
                    </Stepper>
                    <React.Fragment>
                        {activeStep === 0 && <SetupDevice
                            deviceId={deviceId}
                            email={email}
                            isDeviceIdValid={isDeviceIdValid}
                            isEmailValid={isEmailValid}
                            setDeviceId={setDeviceId}
                            setEmail={setEmail}
                            setDeviceIdError={setDeviceIdError}
                            setEmailError={setEmailError}
                        />}
                        {activeStep === 1 && <StepTwo
                            userInfo={userInfo}
                            setUserInfo={setUserInfo}
                            setIsUserInfoValid={setIsUserInfoValid}
                        />}
                        {activeStep === 2 && <StepThree
                            password={password}
                            confirmPassword={confirmPassword}
                            isPasswordValid={isPasswordValid}
                            setPassword={setPassword}
                            setConfirmPassword={setConfirmPassword}
                            setIsPasswordValid={setIsPasswordValid}
                            apiError={apiError}
                        />}
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button
                                onClick={activeStep === steps.length - 1 ? handleRegister : handleNext}
                                disabled={!validateStep(activeStep)}
                                variant='contained'
                                sx={{
                                    width: '90%',
                                    position: 'absolute',
                                    bottom: 10,
                                    right: 20,
                                    left: 20,
                                    justifyContent: 'center',
                                    justifyItems: 'center',
                                }}
                                endIcon={<FontAwesomeIcon icon={activeStep === steps.length - 1 ? '' : faArrowRight} />}
                            >
                                {activeStep === steps.length - 1 ? 'Register' : 'Next'}
                            </Button>
                        </Box>
                    </React.Fragment>
                </Box>
            </Modal>
        </Grid>
    );
}
