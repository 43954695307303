import React from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Grid } from '@mui/material';
import { Button } from 'primereact/button';
import './style.css';
import Loader from '../Loader/loader';

export default function TableView({ tableData, pageNumber, pageSize, totalRecords, setPageNumber, setPageSize, loading, columns }) {

    const onPageChange = (event) => {
        setPageNumber(event.page);
        setPageSize(event.rows);
    };

    if (loading) {
        return <Loader />;
    }

    return (
        <Grid item xs={12} className="table-container mt-4">
            <DataTable
                value={tableData}
                paginator
                first={(pageNumber - 1) * pageSize}
                rows={pageSize}
                rowsPerPageOptions={[5, 10, 25, 50]}
                totalRecords={totalRecords}
                lazy
                loading={loading}
                onPage={onPageChange}
                className="p-datatable border-collapse w-full"
                paginatorTemplate="FirstPageLink PrevPageLink CurrentPageReport NextPageLink LastPageLink RowsPerPageDropdown"
                currentPageReportTemplate="{first} to {last} of {totalRecords}"
                paginatorLeft={<Button type="button" icon="pi pi-refresh" text />}
                paginatorRight={<Button type="button" icon="pi pi-download" text />}
                tableStyle={{ minWidth: '70rem', border: '1px solid #dee2e6' }}
            >
                {columns.map((column) =>{
                    if (column.label !== 'Id')
                    return (
                        <Column field={column.key} header={column.label} className="text-center" sortable style={{ textAlign: 'center', padding: '10px' }} />
                    )
                })}
            </DataTable>
        </Grid>
    );
}
