import React, { useState, useEffect } from 'react';
import Dashboard from '../dashboard/dashboard';
import { getDashboardData, getPowerChart, getPredictionDataChart, getSolarGenerations, getSolarFullDay, getPowerChartWeek, getPowerChartMonth, getDashboardDataNew, getPredictionDataChartNew } from '../../services/powerController';
import { convertTo24Hour, convertTo12Hour, approximateTime } from '../../services/helper';
import Loader from '../../components/Loader/loader';

export default function SolarGenerationDashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartOptions, setChartOptions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataW, setChartDataW] = useState([]);
  const [chartDataM, setChartDataM] = useState([]);
  const [chartPredictData, setChartPredictData] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'Voltage', key: 'voltage' },
    { label: 'Current', key: 'current' },
    { label: 'Power', key: 'solarGeneration' },
    { label: 'Date & Time', key: 'date' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        //const result = await getDashboardData();
        const resultK = await getDashboardDataNew();

        //console.log(result);

        console.log(resultK);

        setData([
          {
            number: (resultK.forecastSolarGeneration/1000 ?? 0).toFixed(2),
            text: 'kWh',
            description: 'Last 24 Hours Solar Energy',
          },
          {
            number: resultK.currentSolarGeneration,
            text: 'kW',
            description: 'Realtime Solar Power',
          },
          {
            number: (resultK.powerConsumption/1000 ?? 0).toFixed(2),
            text: 'kWh',
            description: 'Today’s Forecasted Solar Energy',
          }
        ]);
        if (tableView) {
          const tableResult = await getSolarGenerations(pageSize, pageNumber);
          const fullResult = await getSolarFullDay();
          setFullTableData(fullResult.powerConsumption);
          setTableData(tableResult.solarGeneration);
          setTotalRecords(tableResult.itemCount);
        } else {
          const chartResult = await getPowerChart();
          const chartResultW = await getPowerChartWeek();
          const chartResultM = await getPowerChartMonth();
   
          //const chartPredict = await getPredictionDataChart();
          const chartPredictNew = await getPredictionDataChartNew();

          //console.log(chartPredict)
          //console.log(chartResult)
          
          //console.log(chartPredictNew);
          const chartOptions = {
            
            scales: {
              x: {
                title:  {
                  display: true,
                  text: 'Hour of the Day' // Set x-axis label
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Power Generation (kW)' // Set y-axis label
                }
              }
            }
          };
      


          // Step 1: Extract existing hours and create a set for quick lookup
          const existingHours = chartResult.map(item => item.hour);
          const powerConsumptionMap = new Map(chartResult.map(item => [item.hour, item.powerConsumption]));

          const existingHoursWeek = chartResultW.map(item => item.hour);
          const powerConsumptionMapWeek = new Map(chartResultW.map(item => [item.hour, item.powerConsumption]));

          const existingHoursMonth = chartResultM.map(item => item.hour);
          const powerConsumptionMapMonth = new Map(chartResultM.map(item => [item.hour, item.powerConsumption]));



    
          // Get the current date and time
          const now = new Date();

          const startHour = approximateTime(new Date(now));
          startHour.setHours(startHour.getHours() - 24); // Subtract 24 hours

          const startHourWeek = approximateTime(new Date(now));
          startHourWeek.setHours(startHour.getHours() - 24 * 7); // Subtract 24 hours

          const startHourMonth = approximateTime(new Date(now));
          startHourMonth.setHours(startHour.getHours() - 24 * 26); // Subtract 24 hours

          //console.log(startHourWeek);
          //console.log(startHourMonth);
          // Step 3: Approximate the end time to the nearest 30-minute interval
          const endHour = approximateTime(new Date(now));
          endHour.setMinutes(endHour.getMinutes() - 30);


          const endHourTwice = approximateTime(new Date(now));
          endHourTwice.setHours(startHour.getHours() + 24); 
          endHourTwice.setMinutes(endHour.getMinutes() - 30);

          // Convert to the desired format using the existing utility functions
          const formattedStartHour = convertTo12Hour(startHour);
          const formattedStartHourWeek = convertTo12Hour(startHourWeek);
          const formattedStartHourMonth = convertTo12Hour(startHourMonth);
          const formattedEndHour = convertTo12Hour(endHour);


          const fullTimeRange = [];
          const fullTimeRangeWeek = [];
          const fullTimeRangeMonth = [];

          const fullTimeRangeTwice = [];

          // Generate time range in 30-minute increments
          for (let d = new Date(startHour); d <= endHour; d.setMinutes(d.getMinutes() + 30)) {
            const formattedTime = convertTo12Hour(d); // Convert back to 'HH:MMAM/PM' format
            fullTimeRange.push(formattedTime);
          }

          for (let d = new Date(startHour); d < endHourTwice; d.setMinutes(d.getMinutes() + 30)) {
            const formattedTime = convertTo12Hour(d); // Convert back to 'HH:MMAM/PM' format
            fullTimeRangeTwice.push(formattedTime);
          }

          for (let d = new Date(startHourWeek); d <= endHour; d.setMinutes(d.getMinutes() + 30)) {
            const formattedTimeWeek = convertTo12Hour(d); // Convert back to 'HH:MMAM/PM' format
            fullTimeRangeWeek.push(formattedTimeWeek);
          }

          for (let d = new Date(startHourMonth); d <= endHour; d.setMinutes(d.getMinutes() + 30)) {
            const formattedTimeMonth = convertTo12Hour(d); // Convert back to 'HH:MMAM/PM' format
            fullTimeRangeMonth.push(formattedTimeMonth);
          }

       

          // Step 3: Prepare new labels and data
          const newLabels = [];
          const newData = [];

          fullTimeRange.forEach(time => {
            newLabels.push(time); // Push the formatted 'HH:MMAM/PM'
            // Step 4: Check if the hour exists in the original data
            if (existingHours.includes(time)) {
              newData.push(powerConsumptionMap.get(time));
            } else {
              newData.push(0); // Pad with zero if missing
            }
          });




          setChartOptions(chartOptions);
          setChartData({
            labels: newLabels,
            datasets: [
              {
                label: 'Solar Power (kW)',
                data: newData,
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ],
          });


          setChartPredictData({
            labels: fullTimeRangeTwice,
            datasets: [
            {
              label: 'Solar Power (kW) - Actual',
              data: fullTimeRangeTwice.map((label, index) => {
                // Use the first 48 labels to pull data from newData
                if (index < 48) {
                    const matchingResult = newData[index]; // Directly use the index for newData
                    return matchingResult ? matchingResult : 0;
                } else {
                    return null; // Fill the rest with null for the first dataset
                }
              }),
              fill: false,
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
              tension: 0.4,
              pointRadius: 0,
              pointHoverRadius: 0,
              pointHitRadius: 0,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 0,
            },
            {
              label: 'Solar Power (kW) - Predicted',
              data: fullTimeRangeTwice.map((label, index) => {
                // Use the first 96 labels to pull data from newPredict
                const matchingPredict = chartPredictNew[index]; // Directly use the index for newPredict
                return matchingPredict ? matchingPredict.powerConsumption : 0;
              }),
              fill: false,
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--red-500'),
              tension: 0.4,
              pointRadius: 0,
              pointHoverRadius: 0,
              pointHitRadius: 0,
              pointBorderWidth: 0,
              pointHoverBorderWidth: 0,
            }
            ],
          });

          setChartDataW({
            labels: chartResultW.map((item) => item.hour),
            datasets: [
              {
                label: 'Solar Power (kW)',
                data: chartResultW.map((item) => item.powerConsumption),
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ],
          });
          setChartDataM({
            labels: chartResultM.map((item) => item.hour),
            datasets: [
              {
                label: 'Solar Power (kW)',
                data: chartResultM.map((item) => item.powerConsumption),
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ],
          });

        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
    if (tableView) {
      const interval = setInterval(fetchData, 30000);
      return () => clearInterval(interval);
    }
  }, [ pageNumber, pageSize, tableView]);


  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <Dashboard
      title="Solar Generation"
      view="solarGeneration"
      data={data}
      chartData={chartData}
      chartDataW={chartDataW}
      chartDataM={chartDataM}
      chartPredictData = {chartPredictData}
      chartOptions={chartOptions}
      csvHeaders={csvHeaders}
      tableView={tableView}
      tableData={tableData}
      fullTableData={fullTableData}
      unitGraph="kW"
      totalRecords={totalRecords}
      setTableView={setTableView}
      setPageNumber={setPageNumber}
      setPageSize={setPageSize}
      pageNumber={pageNumber}
      pageSize={pageSize}
    />
  );
}
