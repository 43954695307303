// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* Dashboard.css */

.cards-section {
  display: flex;
  gap: 20px;
  flex-wrap: wrap;
  justify-content: center;
}

.card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 20px;
  background-color: white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  height: 180px ;
}

.upper-text {
  font-weight: bold;
  color: black;
  font-size: 40px;
  color: #111928;
  line-height: 60px;
  font-family: sans-serif;
}

.lower-text {
  font-weight: 600;
  color: gray;
}

.line-chart-section {
  width: 100%;
  margin-top: 20px;
}

/* Add these styles to your CSS file */
.line-chart-container {
  /* Add styles specific to the LineDemo container here */
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/Card/style.css"],"names":[],"mappings":"AAAA,kBAAkB;;AAElB;EACE,aAAa;EACb,SAAS;EACT,eAAe;EACf,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;EACtB,uBAAuB;EACvB,mBAAmB;EACnB,kBAAkB;EAClB,aAAa;EACb,uBAAuB;EACvB,uCAAuC;EACvC,cAAc;AAChB;;AAEA;EACE,iBAAiB;EACjB,YAAY;EACZ,eAAe;EACf,cAAc;EACd,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,gBAAgB;EAChB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,gBAAgB;AAClB;;AAEA,sCAAsC;AACtC;EACE,uDAAuD;EACvD,WAAW;AACb","sourcesContent":["/* Dashboard.css */\n\n.cards-section {\n  display: flex;\n  gap: 20px;\n  flex-wrap: wrap;\n  justify-content: center;\n}\n\n.card {\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  align-items: center;\n  text-align: center;\n  padding: 20px;\n  background-color: white;\n  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);\n  height: 180px ;\n}\n\n.upper-text {\n  font-weight: bold;\n  color: black;\n  font-size: 40px;\n  color: #111928;\n  line-height: 60px;\n  font-family: sans-serif;\n}\n\n.lower-text {\n  font-weight: 600;\n  color: gray;\n}\n\n.line-chart-section {\n  width: 100%;\n  margin-top: 20px;\n}\n\n/* Add these styles to your CSS file */\n.line-chart-container {\n  /* Add styles specific to the LineDemo container here */\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
