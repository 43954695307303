import { User } from "./userModel";

export const getUserInfo = (): User | null => {
    try {
        return JSON.parse(atob(localStorage.getItem("er_a_token")!.split(".")[1]));
    } catch (e) {
        return null;
    }
};


// Helper function to convert 12-hour format to 24-hour format
export function convertTo24Hour(time: string): string {
    const [hour, minutePart] = time.split(':');
    const minute = minutePart.slice(0, 2);
    const period = minutePart.slice(2); // AM or PM

    let hour24 = parseInt(hour);
    if (period === 'PM' && hour24 < 12) {
        hour24 += 12; // Convert PM hour
    } else if (period === 'AM' && hour24 === 12) {
        hour24 = 0; // Midnight case
    }

    return `${hour24.toString().padStart(2, '0')}:${minute}:00`; // Return in 'HH:MM:SS' format
}

// Helper function to convert Date object back to 12-hour format
export function convertTo12Hour(date: Date): string {
    let hours: number = date.getHours();
    const minutes: string = date.getMinutes().toString().padStart(2, '0'); // Ensure two-digit minutes
    const ampm: string = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12; // Convert to 12-hour format
    hours = hours ? hours : 12; // the hour '0' should be '12'
    const formattedTime: string = `${hours.toString().padStart(2, '0')}:${minutes}${ampm}`; // Ensure two-digit hours
    return formattedTime;

}

// Function to approximate the time to the nearest 30-minute interval
export const approximateTime = (date: Date): Date => {
    // Step 1: Calculate the minutes
    const minutes: number = date.getMinutes();

    // Step 2: Round the minutes to the nearest 30-minute interval
    const roundedMinutes: number = Math.floor(minutes / 30) * 30;

    // Step 3: Update the minutes
    date.setMinutes(roundedMinutes);

    // Step 4: Reset the seconds
    date.setSeconds(0);

    return date;
};
