
import React, { useState, useEffect } from 'react';
import Dashboard from '../dashboard/dashboard';
import { getEnergyPrice } from '../../services/powerController';
import Loader from '../../components/Loader/loader';

export default function ElectricityPrice() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartData, setChartData] = useState([]);
  const [chartOptions, setChartOptions] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getEnergyPrice();
        setData([
          {
            number: result.todayAverage,
            text: 'cent/kWh',
            description: 'Today Average Price',
          },
          {
            number: result.currentPrice,
            text: 'cent/kWh',
            description: 'Current Hour Price',
          },
          {
            number: result.nextPrice,
            text: 'cent/kWh',
            description: 'Tomorrows Average Price',
          },
        ]);
        const chartOptions = {

            scales: {
              x: {
                title:  {
                  display: true,
                  text: 'Hour of the Day' // Set x-axis label
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Electricity Price' // Set y-axis label
                }
              }
            }
          };

          setChartOptions(chartOptions);

        setChartData({
          labels: result.energyPrice.map((item) => item.date),
          datasets: [
            {
              label: 'Energy Price',
              data: result.energyPrice.map((item) => item.price),
              type: 'bar',
              fill: true,
              borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
              tension: 0.4,
            }
          ]
        });
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);


  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <Dashboard
      title="Electricity Price"
      view="electricityPrice"
      data={data}
      unitGraph="cents/kWh"
      chartData={chartData}
      chartOptions={chartOptions}
    />
  );
}
