import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Login from './screens/login/login';
import Dashboard from './screens/dashboard/dashboard';
import BatteryPower from './screens/batteryPower/batteryPower';
import Sidebar from './components/sidebar';
import { Grid } from '@mui/material';
import TopHeader from './components/TopHeader/topHeader';
import ElectricityPrice from './screens/electricityPrice/electricityPrice';
import bottomImage from './assets/bottom-image.png';
import HomeAppliance from './screens/homeAppliance/homeAppliance';
import Notifications from './screens/notification/notifications';
import ACLoad from './screens/energyConsumption/ACLoad';
import DCLoad from './screens/energyConsumption/DCLoad';
import GridConsumption from './screens/energyConsumption/gridConsumption';
import SolarGenerationDashboard from './screens/solarGeneration/solarGeneration';

const routes = [
  {
    element: <SolarGenerationDashboard />,
    path: '/dashboard'
  },
  {
    element: <BatteryPower />,
    path: '/battery-Power'
  },
  {
    element: <ACLoad />,
    path: '/ac-consumption'
  },
  {
    element: <DCLoad />,
    path: '/dc-consumption'
  },
  {
    element: <GridConsumption />,
    path: '/grid-consumption'
  },
  {
    element: <ElectricityPrice />,
    path: '/electricity-price'
  },
  {
    element: <HomeAppliance />,
    path: '/home-appliance'
  },
  {
    element: <Notifications />,
    path: '/notification'
  }
]

const isAuthenticated = localStorage.getItem('er_a_token');

const ProtectedRoute = ({ element, ...rest }) => {
  return isAuthenticated ? (
    <>
      <TopHeader />
      <Grid container sm={12} direction={'row'} style={{ backgroundImage: `url(${bottomImage})`, backgroundSize: 'cover' }}>
        <Grid item xs={2}>
          <Sidebar />
        </Grid>
        <Grid xs={10} padding={3} style={{ background: 'rgba(243, 244, 246, 1)' }}>
          {element}
        </Grid>
      </Grid>
    </>
  ) : (
    <Navigate to="/login" replace />
  );
};

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" replace />} />
        <Route path="/login" element={<Login />} />
        {routes.map(({ element, path }) => (
          <Route
            key={path}
            path={path}
            element={<ProtectedRoute element={element} />}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default App;
