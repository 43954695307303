// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-style {
    background: linear-gradient(180deg, #F8FBFC 0%, #FFFFFF 100%);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);
    

}
.value {
  font-family: sans-serif;
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(17, 25, 40, 1);
}

.status {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(107, 114, 128, 1);
}

.status-value {
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: left;
  color: rgba(17, 25, 40, 1);
}
`, "",{"version":3,"sources":["webpack://./src/screens/homeAppliance/style.css"],"names":[],"mappings":"AAAA;IACI,6DAA6D;IAC7D,8CAA8C;;;AAGlD;AACA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;AAC5B;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,6BAA6B;AAC/B;;AAEA;EACE,uBAAuB;EACvB,eAAe;EACf,gBAAgB;EAChB,iBAAiB;EACjB,mBAAmB;EACnB,gBAAgB;EAChB,0BAA0B;AAC5B","sourcesContent":[".card-style {\n    background: linear-gradient(180deg, #F8FBFC 0%, #FFFFFF 100%);\n    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);\n    \n\n}\n.value {\n  font-family: sans-serif;\n  font-size: 18px;\n  font-weight: 600;\n  line-height: 27px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: rgba(17, 25, 40, 1);\n}\n\n.status {\n  font-family: sans-serif;\n  font-size: 16px;\n  font-weight: 400;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: rgba(107, 114, 128, 1);\n}\n\n.status-value {\n  font-family: sans-serif;\n  font-size: 16px;\n  font-weight: 600;\n  line-height: 24px;\n  letter-spacing: 0em;\n  text-align: left;\n  color: rgba(17, 25, 40, 1);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
