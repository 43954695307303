import React from "react";
import { Card, Grid } from "@mui/material";
import ActiveButton from '../../assets/active.png'
import InactiveButton from '../../assets/in-active.png'
import AddNewButton from '../../assets/add-new.png'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import './style.css'
const data = [
    {
        value: 'Electric vehicle',
        status: 'Charging',
        image: ActiveButton,
        charging: '65%'
    },

    {
        value: 'Climate control',
        status: 'Charging',
        image: InactiveButton,
        charging: '65%'
    },

    {
        value: 'Electric vehicle',
        status: 'Charging',
        image: ActiveButton,
        charging: '65%'
    },

    {
        value: 'Socket desktop',
        status: 'Charging',
        image: ActiveButton,
        charging: '65%'
    },

    {
        value: 'Add New Appliance',
        image: AddNewButton,
    }




]

export default function HomeAppliance() {
    return (
        <Grid container>
            {data.map((value, index) => (
                <Grid item xs={12} sm={4} md={3} p={2} key={value.value}>

                    <Card style={{ padding: "20px", position: 'relative' }} className="card-style">
                        <img src={value.image} alt="" style={{ height: '100px', display: 'block', margin: 'auto' }} />
                        <FontAwesomeIcon icon={faPenToSquare} style={{ cursor: "pointer", position: 'absolute', top: '5px', right: '5px', color: 'gray' }} />

                        <Grid item xs={12} textAlign={'left'} mt={4} mb={1}>
                            <span className="value">{value.value}</span>
                        </Grid>
                        <Grid item xs={12} style={{ textAlign: 'left' }}>
                            <span className="status">Status: <span className="status-value">{value.status}</span></span>
                        </Grid>
                        <div style={{ background: "linear-gradient(285.85deg, rgba(28, 100, 242, 0.2) -88.69%, rgba(255, 255, 255, 0) 165.82%)", height: "20px", width: '90%', marginTop: '10px', borderColor: "rgba(28, 100, 242, 1)", borderRadius: '15px', overflow: 'hidden' }}>
                            <div style={{ height: "100%", width: value.charging, background: 'rgba(28, 100, 242, 1)' }}></div>
                        </div>
                        <span>{value.charging}</span>

                    </Card>
                </Grid>
            ))}
        </Grid>
    );
}
