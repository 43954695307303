import React from 'react';
import Card from '../../components/Card/card';
import { Button, Grid, Select, MenuItem } from '@mui/material';
import TableView from '../../components/LineGraph/table';
import ChartComponent from '../../components/LineGraph/chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChartLine, faTable, faFileCsv } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../components/Loader/loader';
import { CSVLink } from 'react-csv';

export default function Dashboard(
  { title, data, csvHeaders, tableData, chartPredictData, chartData, chartDataW, chartDataM, chartOptions, view, fullTableData, totalRecords, setTableView, tableView, setPageNumber, setPageSize, pageNumber, pageSize, unitGraph }
) {

  const [selectedDataset, setSelectedDataset] = React.useState('dataset1');

  const handleToggleTableView = () => {
    setTableView(!tableView);
  };

  const handleDatasetChange = (dataset) => {
    setSelectedDataset(dataset);
  };

  if (!data.length) {
    return <Loader />;
  }

  const getChartData = (dataset) => {
  switch (dataset) {
    case 'dataset1':
      return chartData;
    case 'dataset2':
      return chartDataW;
    case 'dataset3':
      return chartDataM;
    default:
      return chartData; // default to dataset1
  }
 };

  return (
    <Grid container>
      <Grid container xs={12} justifyContent="center">
        {data.map((d, index) => (
          <Grid item xs={12} sm={6} md={4} key={index} p={0.5}>
            <Card
              key={index}
              upperText={d.number + ' ' + d.text}
              lowerText={d.description}
            />
          </Grid>
        ))}
        <Grid container xs={12}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs={8} mt={2}>
              <span className='solar-text'>{title}</span>
            </Grid>
            <Grid item xs={2} mt={2} container justifyContent="flex-start" alignItems="center">
              {
                tableView && (
                  <CSVLink
                    data={fullTableData}
                    headers={csvHeaders}
                    filename={`export_${view}.csv`}
                    className="p-button p-component"
                  >
                    <Button variant="outlined" className="p-button-primary" startIcon={<FontAwesomeIcon icon={faFileCsv} />}>
                      Export to CSV
                    </Button>
                  </CSVLink>
                )
              }
            </Grid>
            {
              view !== 'electricityPrice' && (
                <Grid item xs={2} mt={2} container justifyContent="flex-end" alignItems="center">
                  <Button variant="outlined" onClick={handleToggleTableView} className="p-button-primary" startIcon={<FontAwesomeIcon icon={tableView ? faChartLine : faTable} />}>
                    {tableView ? 'Chart View' : 'Table View'}
                  </Button>
                </Grid>
              )
            }
            {view !== 'electricityPrice' && !tableView && (
                <Grid item xs={2} mt={2} container justifyContent="flex-end" alignItems="center">
  <Select
    label="Select dataset"
    value={selectedDataset}
    onChange={(e) => handleDatasetChange(e.target.value)}
    variant="outlined"
    sx={{ width: 150 }}
    MenuProps={{
      sx: {
        '& .MuiMenuItem-root': {
          fontSize: 14,
          padding: '10px 20px',
          '&:hover': {
            backgroundColor: '#f0f0f0',
          },
        },
      },
    }}
  >
     <MenuItem value="dataset1">Daily</MenuItem>
    <MenuItem value="dataset2">Weekly</MenuItem>
    <MenuItem value="dataset3">Monthly</MenuItem>
    ))}
  </Select>
</Grid>
            )}
            {tableView ? (
              <Grid item xs={12} mt={2}>
                <TableView
                  tableData={tableData}
                  pageSize={pageSize}
                  pageNumber={pageNumber}
                  setPageNumber={setPageNumber}
                  setPageSize={setPageSize}
                  totalRecords={totalRecords}
                  columns={csvHeaders}
                />
              </Grid>
            ) : (
              <Grid item xs={12} mt={2}>
                <ChartComponent chartData={getChartData(selectedDataset)} chartOptions={chartOptions} forecastDataz={chartPredictData} unitGraph={unitGraph} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

