import React, { useState, useEffect } from 'react';
import { Grid } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSun, faDatabase, faChartLine, faWallet, faHouse, faBell, faChevronDown } from '@fortawesome/free-solid-svg-icons';
import './style.css';

const sideBarArray = [
  {
    font: faSun,
    fontColor: 'rgba(17, 25, 40, 1)',
    path: '/dashboard',
    text: 'Solar Energy Generation',
    active: true
  },
  {
    font: faDatabase,
    fontColor: 'rgba(17, 25, 40, 1)',
    path: '/battery-Power',
    text: 'Battery power',
    active: false
  },
  {
    font: faChartLine,
    fontColor: 'rgba(17, 25, 40, 1)',
    subMenuFontColor: 'rgba(17, 25, 40, 1)',
    text: 'Energy Consumption',
    active: false,
    subMenu: [
      {
        path: '/ac-consumption',
        text: 'AC Data',
        active: false
      },
      {
        path: '/dc-consumption',
        text: 'DC Data',
        active: false
      },
      {
        path: '/grid-consumption',
        text: 'Grid Consumption',
        active: false
      },
    ]
  },
  {
    font: faWallet,
    fontColor: 'rgba(17, 25, 40, 1)',
    path: '/electricity-price',
    text: 'Electricity Price',
    active: false
  },
  {
    font: faHouse,
    fontColor: 'rgba(17, 25, 40, 1)',
    path: '/home-appliance',
    text: 'Home Appliance Control',
    active: false
  },
  {
    font: faBell,
    fontColor: 'rgba(17, 25, 40, 1)',
    path: '/notification',
    text: 'Notifications',
    active: false
  }
];

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [menuItems, setMenuItems] = useState(sideBarArray);
  const [openSubMenus, setOpenSubMenus] = useState({});

  useEffect(() => {
    const path = location.pathname;
    setMenuItems(prevMenuItems => {
      const updatedMenuItems = prevMenuItems.map(item => {
        if (item.path === path || (item.subMenu && item.subMenu.some(subItem => subItem.path === path))) {
          item.active = true;
          setOpenSubMenus(prevState => ({
            ...prevState,
            [item.path]: true
          }));
        } else {
          item.active = false;
        }
        return item;
      });
      return updatedMenuItems;
    });
  }, [location.pathname]);

  const handleMenuClick = (path, subMenu) => {
    if (subMenu && subMenu.length > 0) {
      setOpenSubMenus(prevState => ({
        ...prevState,
        [path]: !prevState[path]
      }));
    } else {
      setOpenSubMenus({});
      navigate(path);
    }
  };

  const handleMenuItemClick = (path) => {
    const updatedMenuItems = menuItems.map(item => {
      if (item.subMenu) {
        item.subMenu.forEach(sub => {
          sub.active = sub.path === path;
        });
      }
      return item;
    });
    setMenuItems(updatedMenuItems);
    navigate(path);
  };

  return (
    <Grid container justifyContent="flex-start" className="sidebar">
      <Grid item>
        <ul>
          {menuItems.map((data) => (
            <li
              key={data.text}
              className={data.active && !data.subMenu ? 'li-active' : ''}
              onClick={() => handleMenuClick(data.path, data.subMenu)}
            >
              <FontAwesomeIcon icon={data.font} color={data.fontColor} className='margin-10' />
              <span style={{ color: data.fontColor }}>{data.text}</span>
              {data.subMenu && (
                <FontAwesomeIcon icon={faChevronDown} color={data.fontColor} className='margin-10' />
              )}
              {data.subMenu && openSubMenus[data.path] && (
                <ul>
                  {data.subMenu.map((subItem) => (
                    <li
                      key={subItem.text}
                      className={subItem.active ? 'li-active' : ''}
                      onClick={() => handleMenuItemClick(subItem.path)}
                    >
                      <span style={{ color: data.subMenuFontColor || data.fontColor }}>{subItem.text}</span>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ))}
        </ul>
      </Grid>
    </Grid>
  );
};

export default Sidebar;
