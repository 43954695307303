// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.notification-card {
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);

}
`, "",{"version":3,"sources":["webpack://./src/screens/notification/style.css"],"names":[],"mappings":"AAAA;IACI,kCAAkC;IAClC,8CAA8C;;AAElD","sourcesContent":[".notification-card {\n    background: rgba(255, 255, 255, 1);\n    box-shadow: 0px 1px 3px 0px rgba(0, 0, 0, 0.1);\n\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
