import axios, { AxiosInstance, AxiosError, AxiosResponse, InternalAxiosRequestConfig } from "axios";

export default function axiosClient(token: string | null = null): AxiosInstance {
  const headers = token
    ? {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      }
    : {
        "Content-Type": "application/json",
      };

  const client = axios.create({
    baseURL: "http://api.energy-router.eu/api/v1/",
    headers,
    timeout: 60000,
    withCredentials: false,
  });

  client.interceptors.request.use((config: InternalAxiosRequestConfig) => {
    const token = localStorage.getItem("er_a_token");
    config.headers = config.headers || {};
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  });

  client.interceptors.response.use(
    (response: AxiosResponse) => {
      return response.data;
    },
    async (error: AxiosError) => {
      const originalRequest = error.config as InternalAxiosRequestConfig & { _retry: boolean }; 
      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;
        try {
          const refreshToken = localStorage.getItem("er_r_token");
          if (refreshToken) {
            const response = await axios.post("http://localhost:3008/api/v1/auth/refresh-token", { refreshToken });
            const { accessToken } = response.data;

            localStorage.setItem("er_a_token", accessToken);

            originalRequest.headers.Authorization = `Bearer ${accessToken}`;
            return client(originalRequest);
          } else {
            localStorage.removeItem("er_a_token");
            localStorage.removeItem("er_r_token");
          }
        } catch (refreshError) {
          console.error("Error refreshing token:", refreshError);
          localStorage.removeItem("er_a_token");
          localStorage.removeItem("er_r_token");
        }
      }

      return Promise.reject(error);
    }
  );

  return client;
};
