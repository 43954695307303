import { faCircleCheck, faCircleXmark, faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Grid } from "@mui/material";
import React from "react";
import  './style.css'

const data = [
    {
        icon: <FontAwesomeIcon icon={faTriangleExclamation} style={{ color: "#FFD43B", }} />,
        title: 'Warning Title',
        descriptions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur.'
    },
    {
        icon: <FontAwesomeIcon icon={faCircleCheck} style={{ color: "rgba(14, 159, 110, 1)", }} />,
        title: 'Recommendation Title',
        descriptions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
    {
        icon: <FontAwesomeIcon icon={faCircleXmark} style={{ color: "red" }} />,
        title: 'Error Title',
        descriptions: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.'
    },
]

export default function Notifications() {
    return (
        <Grid container xs={12}>

            {
                data.map(value => {
                    return <Grid container xs={12} md={12} mb={2}>
                        <Card style={{ padding: "15px " ,width:"100%" }} className="notification-card">
                            <Grid item xs={12} mt={1}>
                                {value.icon} <span style={{marginLeft:"5px"}}>{value.title}</span>
                            </Grid>
                            <Grid item xs={12} mt={2} mb={2}>
                                <span>{value.descriptions}</span>
                            </Grid>
                        </Card>
                    </Grid>
                })
            }

        </Grid>
    )
}