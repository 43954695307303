import axiosClient from "./axiosInstance";
interface IRequestLogin {
    email: string;
    password: string;
}
export default async function login(body: IRequestLogin) {
    try {
        const data:any = await axiosClient().post('auth/login', body);
        localStorage.setItem('er_a_token', data.accessToken);
        localStorage.setItem('er_r_token', data.refreshToken);
        delete data.accessToken;
        delete data.refreshToken;
        delete data.message;
        localStorage.setItem('user', JSON.stringify(data));
        return data.message;
    } catch (error:any) {
        console.log('Error: ' + error);
        throw new Error(error.message);
    }
}