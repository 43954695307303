import React, { useState, useEffect } from 'react';
import Dashboard from '../dashboard/dashboard';
import { getDashboardData, getBatteryChart, getBatteryConsumptions, getBatteryConsumptionDay, getBatteryChartWeek, getBatteryChartMonth } from '../../services/powerController';
import Loader from '../../components/Loader/loader';

export default function BatteryPowerDashboard() {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [chartOptions, setChartOptions] = useState([]);
  const [chartData, setChartData] = useState([]);
  const [chartDataW, setChartDataW] = useState([]);
  const [chartDataM, setChartDataM] = useState([]);
  const [tableView, setTableView] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [fullTableData, setFullTableData] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalRecords, setTotalRecords] = useState(0);

  const csvHeaders = [
    { label: 'Id', key: 'id' },
    { label: 'Voltage', key: 'voltage' },
    { label: 'Current', key: 'current' },
    { label: 'Power', key: 'solarGeneration' },
    { label: 'Date & Time', key: 'date' }
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const result = await getDashboardData();
        setData([
          {
            number: result.powerConsumption,
            text: 'kWh',
            description: 'Available Battery Energy (kWh)',
          },
          {
            number: result.currentPowerConsumption,
            text: '%',
            description: 'Battery soC',
          },
          {
            number: result.forecastPowerConsumption ?? 0,
            text: 'kWh',
            description: 'Today’s Forecasted Energy Generation',
          }
        ]);
        if (tableView) {
          const tableResult = await getBatteryConsumptions(pageSize, pageNumber);

          console.log(tableResult);
          const fullResult = await getBatteryConsumptionDay();
          setFullTableData(fullResult.powerConsumption);
          setTableData(tableResult.powerBattery);
          setTotalRecords(tableResult.itemCount);
        } else {
          const chartResult = await getBatteryChart();
          const chartResultW = await getBatteryChartWeek();
          const chartResultM = await getBatteryChartMonth();

          console.log(chartResult);
          console.log(chartResultW);
          console.log(chartResultM);
          const chartOptions = {

            scales: {
              x: {
                title:  {
                  display: true,
                  text: 'Hour of the Day' // Set x-axis label
                }
              },
              y: {
                title: {
                  display: true,
                  text: 'Power Consumption (kW)' // Set y-axis label
                }
              }
            }
          };

          setChartOptions(chartOptions);

          setChartData({
            labels: chartResult.map((item) => item.hour),
            datasets: [
              {
                label: 'Power Consumption (kWh)',
                data: chartResult.map((item) => item.powerConsumption),
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ]
          });
          setChartDataW({
            labels: chartResultW.map((item) => item.hour),
            datasets: [
              {
                label: 'Power Consumption (kWh)',
                data: chartResultW.map((item) => item.powerConsumption),
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ]
          });
          setChartDataM({
            labels: chartResultM.map((item) => item.hour),
            datasets: [
              {
                label: 'Power Consumption (kWh)',
                data: chartResultM.map((item) => item.powerConsumption),
                fill: true,
                borderColor: getComputedStyle(document.documentElement).getPropertyValue('--blue-500'),
                tension: 0.4,
              }
            ]
          });
        }
      } catch (error) {
        setError(error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
    if (tableView) {
      const interval = setInterval(fetchData, 30000);
      return () => clearInterval(interval);
    }
  }, [ pageNumber, pageSize, tableView]);


  if (loading) {
    return <Loader />;
  }

  if (error) {
    return <div>Error loading data: {error.message}</div>;
  }

  return (
    <Dashboard
      title="Battery Power"
      view="batteryPower"
      unitGraph="kW"
      data={data}
      chartData={chartData}
      chartDataW={chartDataW}
      chartDataM={chartDataM}
      chartOptions={chartOptions}
      csvHeaders={csvHeaders}
      tableView={tableView}
      tableData={tableData}
      fullTableData={fullTableData}
      totalRecords={totalRecords}
      setTableView={setTableView}
      setPageNumber={setPageNumber}
      setPageSize={setPageSize}
      pageNumber={pageNumber}
      pageSize={pageSize}
    />
  );
}

