import React, { useState, useEffect } from 'react';
import { Chart } from 'primereact/chart';
import { Grid } from '@mui/material';
import './style.css';
import Loader from '../Loader/loader';

const ChartComponent = ({ chartData, loading, chartOptions, forecastDataz, unitGraph }) => {
    const [averagePower, setAveragePower] = useState(0);
    const [localChartOptions, setLocalChartOptions] = useState(chartOptions);
    const [showForecast, setShowForecast] = useState(false); // Add a new state for the checkbox
    const [forecastData, setForecastData] = useState({}); // Add a new state for the forecast data


    useEffect(() => {
        if (!loading) {
            const data = chartData.datasets[0].data;
            const total = data.reduce((acc, curr) => Number(acc) + Number(curr), 0);
            const average = (data.length > 0) ? (total / data.length).toFixed(2) : 0;
            setAveragePower(average);
            setShowForecast(false);
        }
    }, [chartData, loading]);

    useEffect(() => {
      const documentStyle = getComputedStyle(document.documentElement);
      const textColor = documentStyle.getPropertyValue('--text-color');
      const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
      const surfaceBorder = documentStyle.getPropertyValue('--surface-border');

      const chartOptionsDefault  = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
          legend: {
            labels: {
              color: textColor
            }
          }
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary
            },
            grid: {
              color: surfaceBorder
            }
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder
            },
          }
        }
      };

      const combinedChartOptions = {
       ...chartOptionsDefault,
       scales: {
         x: { ...chartOptionsDefault.scales.x, ...chartOptions.scales.x },
         y: { ...chartOptionsDefault.scales.y, ...chartOptions.scales.y }
        }
      };

      setLocalChartOptions(combinedChartOptions); // Update chart options state with props

    }, [loading, chartOptions]);
     

    const handleForecastChange = (e) => {
    setShowForecast(e.target.checked);
    };

    const getForecastDataset = () => {
  if (forecastDataz) {
    const forecastDataset = {
      label: 'Forecast', // Add a label for the forecast dataset
      data: forecastDataz.datasets[0].data, // Use the data from the forecastDataz prop
      borderColor: 'red', // Set border color to red
      fill: false,
    };
    return forecastDataset;
  } else {
    return null;
  }
}; 
    return (
        <Grid container className="card-line" alignItems="center" justifyContent="space-between">
            {loading ?
                (<Loader />) :
                (
                    <><Grid item xs={12} sm={3}>
                        <Grid item xs={12}>
                            <span className="text-kilo">{averagePower}</span>
                            <span className="kwh">{unitGraph}</span>
                        </Grid>
                        <Grid item xs={12}>
                            <span className="average-power">Average</span>
                        </Grid>
                    </Grid><Grid item container xs={9} direction="row" justifyContent="end" spacing={1} alignItems="center">
                            <Grid item mt={0.2}>
                                <input type="checkbox"  onChange={handleForecastChange}/>
                            </Grid>
                            <Grid item>
                                <span className="show-forecast">Show forecast</span>
                            </Grid>
                        </Grid><Grid item xs={12} style={{ position: 'relative', minHeight: '400px' }}>
                            <Chart type="line" data={showForecast ? forecastDataz : chartData} options={localChartOptions} />
                        </Grid></>
                )
            }

        </Grid>
    );
};

export default ChartComponent;
