// Card.js
import React from 'react';
import PropTypes from 'prop-types';
import './style.css';

const Card = ({ height, width, upperText, lowerText }) => {
  return (
    <div className="card">
      <div className="upper-text">{upperText}</div>
      <div className="lower-text">{lowerText}</div>
    </div>
  );
};

Card.propTypes = {
  height: PropTypes.string.isRequired,
  width: PropTypes.string.isRequired,
  upperText: PropTypes.string.isRequired,
  lowerText: PropTypes.string.isRequired,
};

export default Card;
